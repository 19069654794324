import "./App.css";
import { useState } from "react";
import NavBar from "./NavBar";
import MainMint from "./MainMint";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";

function Mint() {
  return (
    <>
      <BrowserView>
        <div className="mint-div">
        <NavBar></NavBar>
          <Flex
            justify="center"
            align="center"
            paddingBottom="150px"
            paddingRight="50%"
            height="100vh"
          >
            <Box width="520px">
              <div>
                <Text fontSize="76px" textShadow="0 5px #000000">
                  MINT
                </Text>

                <Text
                  fontSize="30px"
                  letterSpacing="-5.5%"
                  lineHeight="35px"
                  fontFamily="inherit"
                  textShadow="0 2px 2px #000000"
                >
                  Why Mint ?<br></br>
                  Coming soon...
                </Text>
              </div>
            </Box>
          </Flex>
        </div>
      </BrowserView>
      <MobileView>
        <div className="mint-div">
          <NavBar></NavBar>
          <Flex
            justify="center"
            align="center"
            paddingBottom="150px"
            // paddingRight="50%"
            height="100vh"
          >
            <Box width="520px">
              <div>
                <Text fontSize="48px" textShadow="0 5px #000000">
                  MINT
                </Text>

                <Text
                  fontSize="18px"
                  letterSpacing="-5.5%"
                  lineHeight="35px"
                  fontFamily="inherit"
                  textShadow="0 2px 2px #000000"
                >
                  Why Mint ?<br></br>
                  Coming soon...
                </Text>
              </div>
            </Box>
          </Flex>
        </div>
      </MobileView>
    </>
  );
}

export default Mint;
