import { useState, useEffect } from "react";
import { ethers, BigNumber } from "ethers";
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import { Image } from '@chakra-ui/react';
import ConfettiExplosion from 'react-confetti-explosion';
// import frappNFT from "./FrappNFT.json";
import logo from './assets/mj-2.png';
import coin from './assets/coin-removal.png';
import gold from './assets/gold.png';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import NavBar from "./NavBar";
import { Wrap, WrapItem, Center } from '@chakra-ui/react';
import "./App.css";


//Sponsor wallet address: 0x747f07F24e6894226a82AdC4610eF96c62Ea0324
//Contract address: 0xf324c96Be0374fC35Ca7A580507948B537cCa98B
//Etherscan account: mflipper

const MainMint = () => {
  const [mintAmount, setMintAmount] = useState(1);
  const [haveMetamask, sethaveMetamask] = useState(true);
  const [accountAddress, setAccountAddress] = useState('');
  const [accountBalance, setAccountBalance] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [isDoubleOrNothing, setDoubleOrNothing] = useState(false);
  //------------------- HEAD OR TAIL ---------------------\\
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [isFlipping, setIsFlipping] = useState(false);
  const [isExploding, setIsExploding] = useState(false);
  const [isLosing, setIsLosing] = useState(false);
  const [isWin, setIsWin] = useState(null);
  const [randomNumber, setRandomNumber] = useState(null);
  const [isWinner, setIsWinner] = useState(null);
  const [betAmount, setBetAmount] = useState('0.005');
  const FEE_PERCENTAGE = 3;



  // useEffect(() => {
  //   console.log('Is Winner? ' + isWinner);
  //   console.log('Random Number: ' + randomNumber);
  // }, [isWinner, randomNumber]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleAmountSelect = (option) => {
    setSelectedAmount(option);
  };


  const handleCoinFlip = () => {
    setIsFlipping(true);
    setTimeout(() => {
      setIsFlipping(false);
      setIsExploding(true);
      setTimeout(() => {
        setIsExploding(false);
      }, 3500);
    }, 4500);
  };
  // Force Wallet Connection
  useEffect(() => {
    const { ethereum } = window;
    const checkMetamaskAvailability = async () => {
      if (!ethereum) {
        sethaveMetamask(false);
      }
      sethaveMetamask(true);
    };
    checkMetamaskAvailability();
    connectWallet();
  }, []);

  const handleOpenDoubleOrNothing = () => {
    setDoubleOrNothing(true);
  }


  const connectWallet = async () => {
    const { ethereum } = window;
    try {
      if (!ethereum) {
        sethaveMetamask(false);
        //alert("Please install metamask");
      }
      // --------------------------------\\
      // CHECK FOR MAINNET FOR PRODUCTION \\
      // ----------------------------------\\
      // const chainId = await window.ethereum.request({ method: 'eth_chainId' });
      // if (chainId != '0x1') {
      //   alert("Please connect to Mainnet");
      // } else {
      //   let balance = await provider.getBalance(accounts[0]);
      //   let bal = ethers.utils.formatEther(balance);
      //   setAccountAddress(accounts[0]);
      //   setAccountBalance(bal);
      //   setIsConnected(true);
      // }
      //--------------------------------\\
      // -------------------------------- \\
      // -----------------------------------\\
      if (haveMetamask) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const accounts = await ethereum.request({
          method: 'eth_requestAccounts',
        });
        let balance = await provider.getBalance(accounts[0]);
        let bal = ethers.utils.formatEther(balance);
        setAccountAddress(accounts[0]);
        setAccountBalance(bal);
        setIsConnected(true);
      }

    } catch (error) {
      setIsConnected(false);
    }
  };


  const playGame = async () => {
    if (typeof window.ethereum !== 'undefined') {
      await window.ethereum.enable();

      setIsExploding(false);
      setIsLosing(false)
      setIsWinner(false);


      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      // Replace with the contract address
      const contractAddress = '0xCE6672296F4287aB7032F104629172710993cc5F';

      // Replace with the contract ABI

      const contractAbi = [
        {
          inputs: [
            {
              internalType: "bytes32",
              name: "requestId",
              type: "bytes32",
            },
            {
              internalType: "bytes",
              name: "data",
              type: "bytes",
            },
          ],
          name: "fulfillUint256",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "renounceOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_airnodeRrp",
              type: "address",
            },
          ],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "previousOwner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "betAmount",
              type: "uint256",
            },
          ],
          name: "RequestNewRandomNumber",
          outputs: [
            {
              internalType: "bytes32",
              name: "",
              type: "bytes32",
            },
          ],
          stateMutability: "payable",
          type: "function",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "bool",
              name: "isWinner",
              type: "bool",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "qrngUint256",
              type: "uint256",
            },
          ],
          name: "Result",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_airnode",
              type: "address",
            },
            {
              internalType: "bytes32",
              name: "_endpointIdUint256",
              type: "bytes32",
            },
            {
              internalType: "address",
              name: "_sponsorWallet",
              type: "address",
            },
          ],
          name: "setRequestParameters",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "startFlip",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "stopFlip",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "transferOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "withdrawAll",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "airnode",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "airnodeRrp",
          outputs: [
            {
              internalType: "contract IAirnodeRrpV0",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "bytes32",
              name: "",
              type: "bytes32",
            },
          ],
          name: "betAmounts",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "endpointIdUint256",
          outputs: [
            {
              internalType: "bytes32",
              name: "",
              type: "bytes32",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "bytes32",
              name: "",
              type: "bytes32",
            },
          ],
          name: "expectingRequestWithIdToBeFullfilled",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "getContractBalance",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "isPaused",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "owner",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "sponsorwallet",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ];


      const contract = new ethers.Contract(contractAddress, contractAbi, signer);

      try {
        setIsFlipping(true); // Set flipping state to true for waiting animation
        const betAmountEther = ethers.utils.parseEther(betAmount).toString();
        //const tempAMount = ethers.utils.parseEther('0.09').toString();
        const betAmountFloat = parseFloat(betAmount);
        console.log(' betAmount Float :' + betAmountFloat)
        const totalAmountFloat = ((betAmountFloat * FEE_PERCENTAGE) / 100) + betAmountFloat;
        console.log('totalAmountFloat' + totalAmountFloat);
        const totalAmount = ethers.utils.parseEther(totalAmountFloat.toString()).toString();
        console.log('totalAmount Ether ' + totalAmount);
        const transaction = await contract.RequestNewRandomNumber(betAmountEther, { value: totalAmount });
        // Wait for the transaction to be mined
        await transaction.wait();

        // Continue with retrieving the result after the transaction is mined
        //await getResult();
        // Query the contract for the latest Result event
        const filter = contract.filters.Result();

        const events = await contract.queryFilter(filter);
        if (events.length > 0) {
          const latestEvent = events[events.length - 1];
          const isWinner = latestEvent.args.isWinner;
          const randomNumber = latestEvent.args.qrngUint256;
          console.log('isWinner is:', isWinner);
          console.log('random Number:', randomNumber);

          // Use the value of isWinner for further processing or displaying to the user
        }

        const result = await contract.queryFilter(contract.filters.Result());


        if (result.length > 0) {
          const latestResult = result[result.length - 1];
          setIsWinner(latestResult.args.isWinner);
          setRandomNumber(latestResult.args.randomNumber);
          // console.log('Is Winner ?:' + latestResult.args.isWinner);
          // console.log('Random Number 2:' + latestResult.args.randomNumber);

          setIsFlipping(false); // Set flipping state to false after receiving the result

          if (latestResult.args.isWinner) {
            setIsExploding(true); // Set exploding state to true if the player wins
          } else {
            setIsLosing(true);
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error('Please install MetaMask!');
    }
  };


  async function handleMint() {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        frappNFTAddress,
        frappNFT.abi,
        signer
      );

      try {
        const response = contract.mint(BigNumber.from(mintAmount), {
          value: ethers.utils.parseEther((0.02 * mintAmount).toString()),
        });
        console.log("response", response);
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  const handleDecrement = () => {
    if (mintAmount <= 1) return;
    setMintAmount(mintAmount - 1);
  };

  const handleIncrement = () => {
    if (mintAmount >= 3) return;
    setMintAmount(mintAmount + 1);
  };

  function Logo() {
    return (
      <>
        <div style={{ paddingLeft: "20vw" }}>
          {isExploding && <ConfettiExplosion duration={5000} particleCount={200} floorWidth="100vw !important" />}
        </div>
        <Image
          borderRadius='full'
          height="32vh"
          width="auto"
          src={logo}
          alt='Matic Flippers' />
      </>
    );
  }

  function LeftIcon() {
    return (
      <Image
        borderRadius='full'
        height="32px"
        width="auto"
        src={coin}
        alt='Matic Flippers' />);
  }

  function Gold() {
    return (
      <><BrowserView>
        <Image
          borderRadius='full'
          height="32px"
          width="auto"
          src={coin}
          alt='Matic Flippers' />
      </BrowserView><MobileView>
          <Image
            borderRadius='full'
            height="24px"
            width="auto"
            src={gold}
            alt='Matic Flippers' />
        </MobileView>

      </>
    );
  }

  function Title() {
    return (
      <>
        <BrowserView>
          <Flex
            justify="center"
            align="center"
            textAlign="center"
            paddingLeft="50%"
            paddingTop="10%"
            height="40px"
            margin="15px"
            backgroundColor="transparent"
          >
            <Text fontSize="56px" textShadow="0 5px #000000" color="#281664">
              M
            </Text>
            <Text fontSize="56px" textShadow="0 5px #000000">
              atic
            </Text>
            <Box width="20px"></Box>
            <Text fontSize="56px" textShadow="0 5px #000000" color="#12cdc5">
              F
            </Text>
            <Text fontSize="56px" textShadow="0 5px #000000">
              lipp
            </Text>
            <Text fontSize="56px" textShadow="0 5px #000000" color="#12cdc5">
              e
            </Text>
            <Text fontSize="56px" textShadow="0 5px #000000" color="#12cdc5">
              r
            </Text>
            <Text fontSize="56px" textShadow="0 5px #000000" color="#12cdc5">
              s
            </Text>
          </Flex></BrowserView>
        <MobileView>
          <Flex
            justify="center"
            align="center"
            textAlign="center"
            // paddingLeft="50%"
            // paddingTop="10%"
            height="40px"
            margin="15px"
            backgroundColor="transparent"
          >
            <Text fontSize="36px" textShadow="0 5px #000000" color="#281664">
              M
            </Text>
            <Text fontSize="36px" textShadow="0 5px #000000">
              atic
            </Text>
            <Box width="20px"></Box>
            <Text fontSize="36px" textShadow="0 5px #000000" color="#12cdc5">
              F
            </Text>
            <Text fontSize="36px" textShadow="0 5px #000000">
              lipp
            </Text>
            <Text fontSize="36px" textShadow="0 5px #000000" color="#12cdc5">
              e
            </Text>
            <Text fontSize="36px" textShadow="0 5px #000000" color="#12cdc5">
              r
            </Text>
            <Text fontSize="36px" textShadow="0 5px #000000" color="#12cdc5">
              s
            </Text>
          </Flex>
        </MobileView>

      </>
    );
  }

  function ConnectOrDouble() {
    return (
      <>
        <br></br><br></br><br></br>
        <BrowserView>
          <Flex
            justify="center"
            align="center"
            textAlign="center"
            paddingLeft="50%"
            paddingTop="15%"
            height="60px"
          >
            {!isConnected ? (
              <Button
                fontWeight="bold"
                backgroundColor="#281664"
                borderRadius="5px"
                boxShadow="0px 2px 2px 1px #0F0F0F"
                color="white"
                cursor="pointer"
                fontFamily="inherit"
                fontSize="16px"
                padding="15px"
                margin="0 0px"
                width="220px"
                onClick={connectWallet}
              >
                CONNECT WALLET</Button>
            ) : (
              <>
                <Box>
                  <br></br>
                  <br></br>
                  <Button
                    backgroundColor="#281664"
                    borderRadius="5px"
                    boxShadow="0px 2px 2px 1px #0F0F0F"
                    color="white"
                    cursor="pointer"
                    fontFamily="inherit"
                    fontSize="18px"
                    padding="12px 20px"
                    margin="0 0px"
                    onClick={handleOpenDoubleOrNothing}
                  >
                    DOUBLED OR RUGGED </Button>
                  <p style={{ color: "#3ae19b", fontSize: "12px" }}>You are connected</p>
                </Box></>
            )}

            {/* <Button
              fontWeight="bold"
              backgroundColor="#281664"
              borderRadius="5px"
              boxShadow="0px 2px 2px 1px #0F0F0F"
              color="white"
              cursor="pointer"
              fontFamily="inherit"
              fontSize="16px"
              padding="15px"
              margin="0 0px"
              width="220px"
              onClick={connectWallet}
            >
              Coming Soon...</Button> */}
          </Flex>
        </BrowserView>
        <MobileView>
          <Flex
            justify="center"
            align="center"
            textAlign="center"
            // paddingLeft="50%"
            paddingTop="20%"
            height="100vh"
          >
            {!isConnected ? (
              <Button
                fontWeight="bold"
                backgroundColor="#281664"
                borderRadius="5px"
                boxShadow="0px 2px 2px 1px #0F0F0F"
                color="white"
                cursor="pointer"
                fontFamily="inherit"
                fontSize="12px"
                padding="15px"
                margin="0 0px"
                width="220px"
                onClick={connectWallet}
              >
                CONNECT WALLET</Button>
            ) : (
              <>
                <Box>
                  <br></br>
                  <br></br>
                  <Button
                    backgroundColor="#281664"
                    borderRadius="5px"
                    boxShadow="0px 2px 2px 1px #0F0F0F"
                    color="white"
                    cursor="pointer"
                    fontFamily="inherit"
                    fontSize="12px"
                    padding="12px 20px"
                    margin="0 0px"
                    onClick={handleOpenDoubleOrNothing}
                  >
                    DOUBLED OR RUGGED </Button>
                  <p style={{ color: "#3ae19b" }}>You are connected</p>
                </Box></>
            )}
          </Flex>
        </MobileView>
      </>
    );
  }

  function FlipChoices() {

    return (
      <>
        <BrowserView>
          <Wrap spacing='10px' justify='center' width="550px" height="550px">
            <WrapItem>
              <Center w='540px' h='25px' >
                <h4>Hello MFer Please Choose</h4>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='540px' h='25px' >
                {isExploding ? <h3 style={{ color: "green" }}>YOU WIN</h3> : <p></p>}
                {isLosing ? <h3 style={{ color: "red" }}>YOU LOSE</h3> : <p></p>}
              </Center>
            </WrapItem>

            <WrapItem>
              <Center w='245px' h='80px' >
                <Button
                  backgroundColor="#281664"
                  //backgroundColor="#281664"
                  className={selectedOption === 'head' ? 'button-focus-head-or-tail' : ''}
                  leftIcon={selectedOption === 'head' ? <LeftIcon /> : ''}
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color="white"
                  cursor="pointer"
                  fontFamily="inherit"
                  fontSize={selectedOption === 'head' ? '18px' : '22px'}
                  padding="15px"
                  width="245px"
                  height="50px"
                  onClick={() => handleOptionSelect('head')}
                >
                  HEAD</Button>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='245px' h='80px' >
                <Button
                  leftIcon={selectedOption === 'tail' ? <LeftIcon /> : ''}
                  className={selectedOption === 'tail' ? 'button-focus-head-or-tail' : ''}
                  backgroundColor="#281664"
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color="white"
                  cursor="pointer"
                  fontFamily="inherit"
                  fontSize={selectedOption === 'tail' ? '18px' : '22px'}
                  padding="15px"
                  margin="0 0px"
                  width="245px"
                  height="50px"
                  onClick={() => handleOptionSelect('tail')}
                >
                  TAIL</Button>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='540px' h='5px' >
                <h4>For</h4>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='160px' h='70px' >
                <Button
                  className={selectedAmount === 1 ? 'button-focus-head-or-tail' : ''}
                  leftIcon={selectedAmount === 1 ? <Gold /> : ''}
                  backgroundColor="#281664"
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color="white"
                  cursor="pointer"
                  fontFamily="inherit"
                  fontSize={selectedAmount === 1 ? '16px' : '18px'}
                  padding="15px"
                  margin="0 0px"
                  w="160px"
                  onClick={() => handleAmountSelect(1)}
                >
                  1 matic</Button>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='160px' h='70px' >
                <Button
                  className={selectedAmount === 2 ? 'button-focus-head-or-tail' : ''}
                  leftIcon={selectedAmount === 2 ? <Gold /> : ''}
                  backgroundColor="#281664"
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color="white"
                  cursor="pointer"
                  fontFamily="inherit"
                  fontSize={selectedAmount === 2 ? '16px' : '18px'}
                  padding="15px"
                  margin="0 0px"
                  w="160px"
                  onClick={() => handleAmountSelect(2)}
                >
                  2 matic</Button>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='160px' h='70px' >
                <Button
                  className={selectedAmount === 5 ? 'button-focus-head-or-tail' : ''}
                  leftIcon={selectedAmount === 5 ? <Gold /> : ''}
                  backgroundColor="#281664"
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color="white"
                  cursor="pointer"
                  fontFamily="inherit"
                  fontSize={selectedAmount === 5 ? '16px' : '18px'}
                  padding="15px"
                  margin="0 0px"
                  w="160px"
                  onClick={() => handleAmountSelect(5)}
                >
                  5 matic</Button>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='160px' h='70px' >
                <Button
                  className={selectedAmount === 10 ? 'button-focus-head-or-tail' : ''}
                  leftIcon={selectedAmount === 10 ? <Gold /> : ''}
                  backgroundColor="#281664"
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color="white"
                  cursor="pointer"
                  fontFamily="inherit"
                  fontSize={selectedAmount === 10 ? '16px' : '18px'}
                  padding="15px"
                  margin="0 0px"
                  w="160px"
                  onClick={() => handleAmountSelect(10)}
                >
                  10 matic</Button>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='160px' h='70px' >
                <Button
                  className={selectedAmount === 25 ? 'button-focus-head-or-tail' : ''}
                  leftIcon={selectedAmount === 25 ? <Gold /> : ''}
                  backgroundColor="#281664"
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color="white"
                  cursor="pointer"
                  fontFamily="inherit"
                  fontSize={selectedAmount === 25 ? '16px' : '18px'}
                  padding="15px"
                  margin="0 0px"
                  w="160px"
                  onClick={() => handleAmountSelect(25)}
                >
                  25 matic</Button>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='160px' h='70px' >
                <Button
                  className={selectedAmount === 50 ? 'button-focus-head-or-tail' : ''}
                  leftIcon={selectedAmount === 50 ? <Gold /> : ''}
                  backgroundColor="#281664"
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color="white"
                  cursor="pointer"
                  fontFamily="inherit"
                  fontSize={selectedAmount === 50 ? '16px' : '18px'}
                  padding="15px"
                  margin="0 0px"
                  w="160px"
                  onClick={() => handleAmountSelect(50)}
                >
                  50 matic</Button>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='540px' h='25px' >
                <h4>Doubled or Rugged ?</h4>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='500px' h='70px' >
                <Button
                  isLoading={isFlipping ? true : false}
                  backgroundColor="#281664"
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color="white"
                  cursor="pointer"
                  fontFamily="inherit"
                  fontSize="26px"
                  padding="10px"
                  margin="0px 0px"
                  height="50px"
                  w="500px"
                  //onClick={() => handleCoinFlip()}
                  onClick={() => playGame()}
                >
                  FLIP</Button>

              </Center>
            </WrapItem>
          </Wrap>
          {isFlipping ? <div class="coin-flip">
            <div class="coin-tails">
              <Image
                src={coin}></Image>
            </div>
            <div class="coin-heads">
              <Image
                src={coin}></Image>
            </div>
          </div> : <></>}

        </BrowserView>

        <MobileView>
          {isFlipping ? <div class="coin-flip">
            <div class="coin-tails">
              <Image
                src={coin}></Image>
            </div>
            <div class="coin-heads">
              <Image
                src={coin}></Image>
            </div>
          </div> : <></>}
          <Wrap spacing='10px' justify='center' width="350px">
            <WrapItem>
              <Center w='330px' h='35px' >
                <h4>Hello MFer Please Choose</h4>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='330px' h='35px' >
                {isExploding ? <h3 style={{ color: "green" }}>YOU WIN</h3> : <p></p>}

              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='155px' h='60px' >
                <Button
                  className={selectedOption === 'head' ? 'button-focus-head-or-tail' : ''}
                  leftIcon={selectedOption === 'head' ? <LeftIcon /> : ''}
                  backgroundColor="#281664"
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"

                  color="white"
                  cursor="pointer"
                  fontFamily="inherit"
                  fontSize={selectedOption === 'head' ? '18px' : '22px'}
                  padding="15px"
                  margin="0 0px"
                  height="50px"
                  width="200px"
                  onClick={() => handleOptionSelect('head')}
                >
                  HEAD</Button>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='155px' h='60px' >
                <Button
                  className={selectedOption === 'tail' ? 'button-focus-head-or-tail' : ''}
                  leftIcon={selectedOption === 'tail' ? <LeftIcon /> : ''}
                  backgroundColor="#281664"
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color="white"
                  cursor="pointer"
                  fontFamily="inherit"
                  fontSize={selectedOption === 'head' ? '18px' : '22px'}
                  padding="15px"
                  margin="0 0px"
                  height="50px"
                  width="200px"
                  onClick={() => handleOptionSelect('tail')}
                >
                  TAIL</Button>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='330px' h='25px' >
                <h4>For</h4>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='100px' h='55px' >
                <Button
                  className={selectedAmount === 1 ? 'button-focus-head-or-tail' : ''}
                  leftIcon={selectedAmount === 1 ? <Gold /> : ''}
                  backgroundColor="#281664"
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color="white"
                  cursor="pointer"
                  fontFamily="inherit"
                  fontSize={selectedAmount === 1 ? '8px' : '12px'}
                  padding="15px"
                  margin="0 0px"
                  w="100px"
                  onClick={() => handleAmountSelect(1)}
                >
                  1 matic</Button>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='100px' h='55px' >
                <Button
                  className={selectedAmount === 2 ? 'button-focus-head-or-tail' : ''}
                  leftIcon={selectedAmount === 2 ? <Gold /> : ''}
                  backgroundColor="#281664"
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color="white"
                  cursor="pointer"
                  fontFamily="inherit"
                  fontSize={selectedAmount === 2 ? '8px' : '12px'}
                  padding="15px"
                  margin="0 0px"
                  w="100px"
                  onClick={() => handleAmountSelect(2)}
                >
                  2 matic</Button>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='100px' h='55px' >
                <Button
                  className={selectedAmount === 5 ? 'button-focus-head-or-tail' : ''}
                  leftIcon={selectedAmount === 5 ? <Gold /> : ''}
                  backgroundColor="#281664"
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color="white"
                  cursor="pointer"
                  fontFamily="inherit"
                  fontSize={selectedAmount === 5 ? '8px' : '12px'}
                  padding="15px"
                  margin="0 0px"
                  w="100px"
                  onClick={() => handleAmountSelect(5)}
                >
                  5 matic</Button>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='100px' h='55px' >
                <Button
                  className={selectedAmount === 10 ? 'button-focus-head-or-tail' : ''}
                  leftIcon={selectedAmount === 10 ? <Gold /> : ''}
                  backgroundColor="#281664"
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color="white"
                  cursor="pointer"
                  fontFamily="inherit"
                  fontSize={selectedAmount === 10 ? '8px' : '12px'}
                  padding="15px"
                  margin="0 0px"
                  w="100px"
                  onClick={() => handleAmountSelect(10)}
                >
                  10 matic</Button>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='100px' h='55px' >
                <Button
                  className={selectedAmount === 25 ? 'button-focus-head-or-tail' : ''}
                  leftIcon={selectedAmount === 25 ? <Gold /> : ''}
                  backgroundColor="#281664"
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color="white"
                  cursor="pointer"
                  fontFamily="inherit"
                  fontSize={selectedAmount === 25 ? '8px' : '12px'}
                  padding="15px"
                  margin="0 0px"
                  w="100px"
                  onClick={() => handleAmountSelect(25)}

                >
                  25 matic</Button>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='100px' h='55px' >
                <Button
                  className={selectedAmount === 50 ? 'button-focus-head-or-tail' : ''}
                  leftIcon={selectedAmount === 50 ? <Gold /> : ''}
                  backgroundColor="#281664"
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color="white"
                  cursor="pointer"
                  fontFamily="inherit"
                  fontSize={selectedAmount === 50 ? '9px' : '12px'}
                  padding="15px"
                  margin="0 0px"
                  w="100px"
                  onClick={() => handleAmountSelect(50)}

                >
                  50 matic</Button>
              </Center>

            </WrapItem>
            <WrapItem>
              <Center w='330px' h='25px' >
                <h4>Doubled or Rugged ?</h4>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center w='330px' h='60px' >
                <Button
                  isLoading={isFlipping ? true : false}
                  // loadingText='Flipping'
                  backgroundColor="#281664"
                  borderRadius="5px"
                  boxShadow="0px 2px 2px 1px #0F0F0F"
                  color="white"
                  cursor="pointer"
                  fontFamily="inherit"
                  fontSize="22px"
                  padding="15px"
                  margin="0 0px"
                  height="50px"
                  width="330px"
                  onClick={() => handleCoinFlip()}
                >
                  FLIP</Button>

              </Center>
            </WrapItem>
          </Wrap>
        </MobileView></>
    )
  }

  return (
    <>
      <BrowserView>
        {isDoubleOrNothing ?
          (
            <div className="overlay-double-or-rugged">
              <NavBar></NavBar>

              <Flex
                justify="center"
                align="center"
                textAlign="center"
                backgroundColor="transparent"
                height="100vh"
              >
                <Box>
                  <Logo></Logo>
                  <FlipChoices></FlipChoices>
                </Box>
              </Flex>
              <Flex
                justify="center"
                align="center"
                textAlign="center"
                backgroundColor="transparent"
                height="100vh"
                width="100vw"
              >
                <Box
                  textAlign="center"
                  width="70vw">
                  <h1>Read The Matic Flippers Trustability Assurance</h1>
                  <h2>The idea is very simple, we get a number that no one can guess a, and we look at whether it's odd or even </h2>
                  <h3><a href="https://sepolia.etherscan.io/address/0x51ab1bd1d6eb8f445495c410264cb6ec1717ef21">Read the Contract</a></h3>
                </Box>
              </Flex>
            </div>
          ) : (
            <>
              <div className="overlay">
                <NavBar></NavBar>
                <Title />
                <ConnectOrDouble />
              </div>
            </>
          )}
      </BrowserView>
      <MobileView>
        <div className="mobile-double-or-rugged">
          {isDoubleOrNothing ?
            (
              <div className="overlay-double-or-rugged">
                {/* <NavBar></NavBar> */}
                <Flex
                  justify="center"
                  align="center"
                  textAlign="center"
                  backgroundColor="transparent"
                  height="750px"
                >
                  <Box>
                    <NavBar></NavBar>
                    <Logo></Logo>
                    <FlipChoices></FlipChoices>
                  </Box>
                </Flex>
              </div>
            ) : (
              <>
                <div className="overlay">
                  <NavBar></NavBar>
                  <Title />
                  <ConnectOrDouble />
                </div>
              </>
            )}
        </div>
      </MobileView>
    </>
  );
};

export default MainMint;


// {isConnected ? (
//   <>
//     <Flex align="center" justify="center">
//       <Button
//         backgroundColor="#085868"
//         borderRadius="5px"
//         boxShadow="0px 2px 2px 1px #0F0F0F"
//         color="white"
//         cursor="pointer"
//         fontFamily="inherit"
//         padding="15px"
//         margin="0 15px"
//         onClick={handleDecrement}
//       >
//         -
//       </Button>
//       <Input
//         readOnly
//         fontFamily="inherit"
//         width="100px"
//         height="40px"
//         textAlign="center"
//         paddingLeft="19px"
//         marginTop="10px"
//         type="number"
//         value={mintAmount}
//       ></Input>
//       <Button
//         backgroundColor="#085868"
//         borderRadius="5px"
//         boxShadow="0px 2px 2px 1px #0F0F0F"
//         color="white"
//         cursor="pointer"
//         fontFamily="inherit"
//         padding="15px"
//         margin="0 15px"
//         onClick={handleIncrement}
//       >
//         +
//       </Button>
//     </Flex>
//     <br></br>
//     <br></br>
//     <Button
//       backgroundColor="#085868"
//       borderRadius="5px"
//       boxShadow="0px 2px 2px 1px #0F0F0F"
//       color="white"
//       cursor="pointer"
//       fontFamily="inherit"
//       padding="15px"
//       margin="0 15px"
//       // onClick={handleMint}
//       width="150px"
//       height="60px"
//     >
//       Mint
//     </Button>
//   </>
// ) : (
//   <p></p>
// )}

{/* <Text fontSize="26px" textShadow="0 5px #000000">
            Coming Soon....
          </Text> */}