import "./App.css";
import { useState } from "react";
import NavBar from "./NavBar";
import MainMint from "./MainMint";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";

function About() {
  return (
    <>
      <BrowserView>
        <div className="about-div">
          <NavBar></NavBar>
          <Flex
            justify="center"
            align="center"
            paddingBottom="150px"
            paddingRight="50%"
            height="100vh"
          >
            <Box width="520px">
              <div>
                <Text fontSize="46px" textShadow="0 5px #000000">
                  About
                </Text>

                <Text
                  fontSize="22px"
                  letterSpacing="-5.5%"
                  lineHeight="35px"
                  fontFamily="inherit"
                  textShadow="0 2px 2px #000000"
                >
                A polygon nft collection of 1000 pieces. We are fixing the
                  machine stay with us...
                </Text>
              </div>
            </Box>
          </Flex>
        </div>
      </BrowserView>
      <MobileView>
        <div className="about-div">
        <NavBar></NavBar>
          <Flex
            justify="center"
            // align="center"
            paddingBottom="150px"
            paddingTop="0px"
            height="75vh"
          >
            <Box width="320px">
              <div>
                <Text fontSize="36px" textShadow="0 5px #000000">
                  About
                </Text>

                <Text
                  fontSize="12px"
                  letterSpacing="-5.5%"
                  lineHeight="22px"
                  fontFamily="inherit"
                  textShadow="0 2px 2px #000000"
                >
                  A polygon nft collection of 1000 pieces. We are fixing the
                  machine stay with us...
                </Text>
              </div>
            </Box>
          </Flex>
        </div>
      </MobileView>
    </>
  );
}

export default About;
