import "./App.css";
import { useState } from "react";
import NavBar from "./NavBar";
import MainMint from "./MainMint";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import {
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
function Team() {
  return (
    <>
      <BrowserView>

        <div className="team-div">
          <NavBar></NavBar>
          <Flex
            justify="center"
            align="center"
            paddingBottom="150px"
            paddingLeft="60%"
            height="100vh"
          >
            <Box width="520px">
              <div>
                <Text fontSize="32px" textShadow="0 5px #000000">
                  Road Map V1
                </Text>
                <Text
                  fontSize="16px"
                  letterSpacing="-1.5%"
                  lineHeight="25px"
                  fontFamily="inherit"
                  textShadow="0 2px 2px #000000"
                >
                  1. The Most Reliable and Trustuble Flip Website on Polygon
                </Text>
                <Text
                  fontSize="22px"
                  letterSpacing="-1.5%"
                  lineHeight="35px"
                  fontFamily="inherit"
                  textShadow="0 2px 2px #000000"
                >
                  |
                </Text>

                <Text
                  fontSize="16px"
                  letterSpacing="-1.5%"
                  lineHeight="35px"
                  fontFamily="inherit"
                  textShadow="0 2px 2px #000000"
                >
                  2.Marketing

                  Discord

                  1000 piece NFT Collection MINT
                </Text>
                <Text
                  fontSize="22px"
                  letterSpacing="-1.5%"
                  lineHeight="35px"
                  fontFamily="inherit"
                  textShadow="0 2px 2px #000000"
                >
                  |
                </Text>
                <Text
                  fontSize="16px"
                  letterSpacing="-1.5%"
                  lineHeight="35px"
                  fontFamily="inherit"
                  textShadow="0 2px 2px #000000"
                >
                  3.Equivalent distribution of 80% revshare per week to 1000 NFTs
                  Community-focused flip events
                </Text>
                <Text
                  fontSize="22px"
                  letterSpacing="-1.5%"
                  lineHeight="35px"
                  fontFamily="inherit"
                  textShadow="0 2px 2px #000000"
                >
                  |
                </Text>
                <Text
                  fontSize="16px"
                  letterSpacing="-1.5%"
                  lineHeight="35px"
                  fontFamily="inherit"
                  textShadow="0 2px 2px #000000"
                >
                  4.Ordinal Flippers maybe? Maybe not? Community decide.
                  New Art
                </Text>
              </div>
            </Box>
          </Flex>
        </div>
      </BrowserView>
      <MobileView>
        <div className="team-div">
          <NavBar></NavBar>
          <Flex
            justify="center"
            // align="center"
            paddingBottom="150px"
            paddingTop="0px"
            height="75vh"
          >
            <Box width="520px">
              <div>
                <Text fontSize="32px" textShadow="0 5px #000000">
                  Road Map V1
                </Text>
                <Text
                  fontSize="16px"
                  letterSpacing="-1.5%"
                  lineHeight="25px"
                  fontFamily="inherit"
                  textShadow="0 2px 2px #000000"
                >
                  1. The Most Reliable and Trustuble Flip Website on Polygon
                </Text>
                <Text
                  fontSize="22px"
                  letterSpacing="-1.5%"
                  lineHeight="35px"
                  fontFamily="inherit"
                  textShadow="0 2px 2px #000000"
                >
                  |
                </Text>

                <Text
                  fontSize="16px"
                  letterSpacing="-1.5%"
                  lineHeight="35px"
                  fontFamily="inherit"
                  textShadow="0 2px 2px #000000"
                >
                  2.Marketing

                  Discord

                  1000 piece NFT Collection MINT
                </Text>
                <Text
                  fontSize="22px"
                  letterSpacing="-1.5%"
                  lineHeight="35px"
                  fontFamily="inherit"
                  textShadow="0 2px 2px #000000"
                >
                  |
                </Text>
                <Text
                  fontSize="16px"
                  letterSpacing="-1.5%"
                  lineHeight="35px"
                  fontFamily="inherit"
                  textShadow="0 2px 2px #000000"
                >
                  3.Equivalent distribution of 80% revshare per week to 1000 NFTs
                  Community-focused flip events
                </Text>
                <Text
                  fontSize="22px"
                  letterSpacing="-1.5%"
                  lineHeight="35px"
                  fontFamily="inherit"
                  textShadow="0 2px 2px #000000"
                >
                  |
                </Text>
                <Text
                  fontSize="16px"
                  letterSpacing="-1.5%"
                  lineHeight="35px"
                  fontFamily="inherit"
                  textShadow="0 2px 2px #000000"
                >
                  4.Ordinal Flippers maybe? Maybe not? Community decide.
                  New Art
                </Text>
              </div>
            </Box>
            <br></br>
            <br></br>
          </Flex>
        </div>
      </MobileView>
    </>
  );
}

export default Team;
