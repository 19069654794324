import React from "react";
import { Box, Button, Flex, Image, Link, Spacer } from "@chakra-ui/react";
import Facebook from "./assets/social-media-icons/facebook_32x32.png";
import Twitter from "./assets/social-media-icons/twitter_32x32.png";
import Email from "./assets/social-media-icons/email_32x32.png";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

const NavBar = () => {



  return (
    <>
      <BrowserView>
        <Flex justify="space-between" align="center" padding="0px 30px" >
          {/* Left Side -- Social Media Icons */}
          <Flex justify="space-around" width="10%" padding="0 30px">

            <Box margin="0 15px" style={{ fontSize: "28px" }} ><a className="navbar-item" href="/"> MFers</a></Box>


            {/* <Link href="https://twitter.com/FlippersOrdinal">
              <Image src={Twitter} boxSize="42px" margin="0 15px" />
            </Link> */}
            {/* <Link href="#">
              <Image src={Email} boxSize="42px" margin="0 15px" />
            </Link> */}
          </Flex>
          {/* Right Side -- Sections and Connect */}
          <Flex justify="space-between" align="center" padding="30px 30px">
            <Box margin="0 15px" style={{ fontSize: "22px" }} > <a className="navbar-item" href="about"> About</a></Box>
            <Spacer></Spacer>
            <Box margin="0 15px" style={{ fontSize: "22px" }} > <a className="navbar-item" href="team"> Roadmap</a></Box>
            <Spacer></Spacer>
            <Box margin="0 15px" style={{ fontSize: "22px" }}> <a className="navbar-item" href="mint"> Mint</a></Box>

            <Spacer></Spacer>
            {/* Connect */}
            {/* {isConnected ? (
              <Box margin="0 15px" color="green" style={{fontSize:"30px"}}>
                Connected
              </Box>
            ) : (
              <Button
                backgroundColor="#281664"
                borderRadius="5px"
                boxShadow="0px 2px 2px 1px #0F0F0F"
                color="white"
                cursor="pointer"
                fontSize="18px"
                fontFamily="inherit"
                padding="10px"
                margin="0 15px"
                // onClick={connectAccount}
              >
                Connect
              </Button>
            )} */}
          </Flex>
        </Flex>
      </BrowserView>
      <MobileView>
        {" "}
        <br></br>
        <Flex justify="center" padding="0px 0px">
          {/* Left Side -- Social Media Icons */}
          <Flex justify="center" padding="0 0px">

          {/* <Box margin="0 15px" style={{ fontSize: "14px" }} ><a className="navbar-item" href="/"> MFers</a></Box> */}

            {/* <Link href="#">
              <Image src={Facebook} boxSize="42px" margin="0 15px" />
            </Link> */}
            {/* <Link href="https://twitter.com/FlippersOrdinal">
              <Image src={Twitter} boxSize="42px" margin="0 0px" />
            </Link> */}
            {/* <Link href="#">
              <Image src={Email} boxSize="42px" margin="0 15px" />
            </Link> */}
          </Flex>
          {/* Right Side -- Sections and Connect */}
          <Flex justify="center" padding="15px 0px">
            <Box margin="0 10px" style={{ fontSize: "16px" }}> <a className="navbar-item" href="/"> Flip</a></Box>
            <Spacer></Spacer>
            <Box margin="0 10px" style={{ fontSize: "16px" }} > <a className="navbar-item" href="about"> About</a></Box>
            <Spacer></Spacer>
            <Box margin="0 10px" style={{ fontSize: "16px" }} > <a className="navbar-item" href="team"> Roadmap</a></Box>
            <Spacer></Spacer>
            <Box margin="0 10px" style={{ fontSize: "16px" }}> <a className="navbar-item" href="mint"> Mint</a></Box>
            
            <Spacer></Spacer>
          </Flex>
        </Flex>
        <br></br>
        {/* Connect */}
        {/* {isConnected ? (
          <Box margin="0 15px" color="green">
            Connected
          </Box>
        ) : (
          <Button
            backgroundColor="#482279"
            borderRadius="5px"
            boxShadow="0px 2px 2px 1px #0F0F0F"
            color="white"
            cursor="pointer"
            fontFamily="inherit"
            padding="15px"
            margin="0 15px"
            // onClick={connectAccount}
          >
            Connect
          </Button>
        )} */}
      </MobileView>
    </>
  );
};

export default NavBar;
