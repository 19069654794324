import "./App.css";
import { useState } from "react";
import NavBar from "./NavBar";
import MainMint from "./MainMint";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import About from "./About";
import Team from "./Team";
import Mint from "./Mint";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";


const Animated = () => {
  const location = useLocation();
  return (
    // New commit for test
    <Routes location={location} key={location.pathname}>
      <Route path="team" element={<Team />}></Route>
      <Route path="about" element={<About />}></Route>
      <Route path="mint" element={<Mint />}></Route>
      <Route path="/" element={<MainMint />}></Route>
    </Routes>
  );
};
function App() {
  return (
    <>
      <Router>
        <Animated />
      </Router>
    </>
  );
}

export default App;


// function App() {

//   return (
//     <>
//       <BrowserView>
//         <div className="App">
//           <MainMint />
//           <About></About>
//           <Team></Team>
//           <Mint></Mint>
//           {/* <div className="moving-background"></div> */}
//         </div>

//       </BrowserView>
//       <MobileView>
//         <div className="App">
//           <div className="overlay">
//             <MainMint />
//             <NavBar />
//           </div>
//           <About></About>
//           <Team></Team>
//           <Mint></Mint>
//           {/* <div className="moving-background"></div> */}
//         </div>
//       </MobileView>
//     </>
//   );
// }

// export default App;
